<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <div v-if="isAdministrator || isManager || isAuditor || isRoutingManager">
            <router-panel/>
        </div>        
        <div v-else>
            <router-panel v-if="isRouting"/>
            
            <logistic-panel v-if="isLogistics || isRoutingManager" :isRefresh="isRefreshRoutings" />
        </div>         
    </b-overlay>
</template>

<script>
import router from "@/router";
import store from "@/store";
import BCardCode from "@core/components/b-card-code";
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
    BCardText,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    VBToggle,
    BOverlay,
    BSpinner,
    BTabs,  
    BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import EventBus from "@/event-bus"
import RouterPanel from '@/@core/components/app-rountings/RouterPanel.vue';
import LogisticPanel from '@/@core/components/app-rountings/LogisticPanel.vue';

export default {
    name: "RoutingsIndex",
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
        BCardText,
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormCheckbox,
        BFormTextarea,
        BAvatar,
        vSelect,
        flatPickr,
        ValidationProvider,
        BFormInvalidFeedback,
        ValidationObserver,
        BOverlay,
        BSpinner,
        BCardCode,
        BTabs,  
        BTab,

        // Custom Components
        RouterPanel,
        LogisticPanel,
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            loading: false,
            activeSearchCustomer: false,
            resetSearch: false,
            invoice: {},
            isOnPreRoute: false,
            isSearch: false,
            isRefreshRoutings: false,
        }
    },
    computed: {
        isAuditor () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAuditorRol'] : false
        },
        isManager () {
            return store.getters['auth/getUser']?.customRol.id == store.getters['auth/getGerenteRol']
        },
        isAdministrator () {
            return store.getters['auth/getUser']?.customRol.id == store.getters['auth/getAdministratorRol']
        },
        isLogistics() {
            return store.getters['auth/getUser']?.customRol.id == store.getters['auth/getIsLogisticRol']
        },
        isYard() {
            return store.getters['auth/getUser']?.customRol.id == store.getters['auth/getPatioRol']
        },
        isRouting() {            
            return store.getters['auth/getUser']?.customRol.id == store.getters['auth/getCashierRol'] || 
            store.getters['auth/getUser']?.customRol.id == store.getters['auth/getSuperCashierRol']
        },
        isRoutingManager() {
            return store.getters['auth/getUser']?.customRol.id == store.getters['auth/getIsRoutingManager']
        }
    },
    created() {
        EventBus.$on('refreshRoutings', (value) => {
            console.log('Hola aqui se hace el refresh pero desde Index.vue en LogisticPanel')
            this.isRefreshRoutings = value
        });
    },
    async mounted() {
        console.log('RoutingsIndex mounted')	
    },
    methods: {},
    watch: {}
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";

.card-footer {
    padding: 0.75rem 0.75rem;
    background-color: rgba(34, 41, 47, 0.03);
    border-top: 1px solid rgba(34, 41, 47, 0.125);
}
</style>